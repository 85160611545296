//import logo from './logo.svg';
import logo from './Cloud Coder Logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Cloud Coder LLC" />
      </header>
    </div>
  );
}

export default App;
